<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col>
            <b-form-group
              label="Hızlı Arama"
              label-for="keyword"
            >
              <b-form-input
                id="keyword"
                v-model="filterParams.keyword"
                placeholder="İş Emri No, Araç Plaka, Müşteri Adı..."
                @keydown.enter="getDataList"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Danışman"
              label-for="username"
            >
              <v-select
                id="username"
                v-model="filterParams.username"
                :options="filterUsers"
                placeholder="Danışman"
                :reduce="item => item.value"
                @input="getDataList"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Lokasyon"
              label-for="location"
            >
              <v-select
                id="location"
                v-model="filterParams.location"
                :options="filterLocations"
                placeholder="Lokasyon"
                label="title"
                :reduce="item => item.id"
                @input="getDataList"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <pre-loading v-if="dataLoading" />
      <b-table
        v-else-if="dataList.length"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
      >
        <template #cell(work_no)="data">
          <div class="font-weight-bolder font-medium-2 text-primary">
            {{ data.item.work_no }}
          </div>
          <div class="font-weight-bolder font-small-3">
            {{ data.item.Danisman }}
          </div>
        </template>
        <template #cell(location)="data">
          {{ data.item.location }}
        </template>
        <template #cell(Arac_Model)="data">
          <div class="font-weight-bolder">
            {{ data.item.Marka }}
          </div>
          <div class="font-small-2">
            {{ data.item.Arac_Model }}
          </div>
          <div class="font-small-2 text-info">
            {{ data.item.Sase_No }}
            {{ data.item.Motor_No }}
          </div>
          <div class="text-danger font-weight-bolder font-medium-2">
            {{ data.item.Plaka }}
          </div>
        </template>
        <template #cell(control)="data">
          <div>
            <b-button
              variant="success"
              size="sm"
              @click="openModal(data.item.work_no)"
            >
              <FeatherIcon icon="SendIcon" />
              SMS GÖNDER
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-body v-else>
        <b-alert
          variant="info"
          show
        >
          <div class="alert-body text-center p-1">
            <div>
              <FeatherIcon
                icon="InfoIcon"
                size="24"
              />
            </div>
            <div class="font-medium-2">
              Sms bekleyen iş emri bulunmuyor.
            </div>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
    <modal-sms />
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/Loading.vue'
import ModalSms from '@/views/Service_work_orders/Sms/ModalSms.vue'

export default {
  name: 'Index',
  components: {
    BButton,
    BTable,
    BCard,
    BCardBody,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    PreLoading,
    ModalSms,
  },
  data() {
    return {
      filterParams: {
        keyword: null,
        danisman: null,
        location: null,
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      fields: [
        {
          key: 'work_no',
          label: 'İŞ EMRİ NO',
        },
        {
          key: 'customer_name',
          label: 'MÜŞTERİ ADI',
        },
        {
          key: 'Arac_Model',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'location',
          label: 'LOKASYON',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceWorkOrderSms/dataList']
    },
    dataCount() {
      return this.$store.getters['serviceWorkOrderSms/dataCounts']
    },
    saveData() {
      return this.$store.getters['serviceWorkOrderSms/dataSaveStatus']
    },
    filterUsers() {
      return this.$store.getters['serviceWorkOrderSms/filterUsers']
    },
    filterLocations() {
      return this.$store.getters['serviceWorkOrderSms/filterLocations']
    },
    dataLoading() {
      return this.$store.getters['serviceWorkOrderSms/dataLoading']
    },
    modalData() {
      return this.$store.getters['serviceWorkOrderSms/modalData']
    },
    sendSmsResult() {
      return this.$store.getters['serviceWorkOrderSms/sendSmsResult']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    sendSmsResult(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Bilgilendirme mesajı başarıyla gönderildi.!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
        this.$store.commit('serviceWorkOrderSms/RESET_MODAL_DATA')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Bilgilendirme mesajı gönderilemedi.!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    openModal(workNo) {
      this.$store.dispatch('serviceWorkOrderSms/getData', { work_no: workNo })
        .then(response => {
          this.modalData.data = response.data
          this.modalData.smsList = response.smsList
          this.modalData.status = true
        })
    },
    getDataList() {
      this.$store.dispatch('serviceWorkOrderSms/getPendingList', this.filterParams)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceWorkOrderSms/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
