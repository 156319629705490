<template>
  <b-modal
    v-model="modalData.status"
    centered
    size="lg"
    hide-footer
    title="İş Emri Sms Bilgisi"
    lazy
  >
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              İş Emri No
            </div>
            <div class="font-weight-bolder text-danger">
              {{ modalData.data.work_no }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Müşteri Bilgisi
            </div>
            <div class="font-weight-bolder">
              {{ modalData.data.customer_name }}
            </div>
            <div>{{ modalData.data.customer_phone }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Danışman
            </div>
            <div class="font-weight-bolder text-danger">
              {{ modalData.data.Danisman }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Araç Bilgisi
            </div>
            <div>
              <div class="font-weight-bold">
                {{ modalData.data.Marka }}
              </div>
              <div class="font-small-2">
                {{ modalData.data.Arac_Model }}
              </div>
              <div class="font-small-2 text-info">
                {{ modalData.data.Sase_No }}
              </div>
              <div class="font-small-2 text-info">
                {{ modalData.data.Motor_No }}
              </div>
              <div class="font-weight-bolder text-danger">
                {{ modalData.data.Plaka }}
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <div
          v-for="sms in modalData.smsList"
          :key="sms.id"
          class="border-light p-1 mb-1 rounded"
        >
          <div class="font-weight-bold text-primary">
            {{ sms.is_start === '1'? 'Başlangıç' : 'Teslimat' }}
          </div>
          <div>{{ sms.message }}</div>
          <template>
            <hr>
            <div
              v-if="sms.status === '1'"
              class="text-success font-weight-bold font-small-2"
            >
              {{ sms.created? moment(sms.created).format('llll') : '' }}
              SMS gönderildi.
            </div>
            <div
              v-if="sms.status === '0'"
              class="text-danger font-weight-bold font-small-2 d-flex align-items-center justify-content-between"
            >
              <div>
                SMS gönderilmedi.
              </div>
              <div>
                <b-button
                  variant="danger"
                  size="sm"
                  pill
                  @click="sendSms(sms.id)"
                >
                  <FeatherIcon icon="SendIcon" />
                  Şimdi Gönder
                </b-button>
              </div>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import {
  BModal, BRow, BCol, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ModalSms',
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  computed: {
    modalData() {
      return this.$store.getters['serviceWorkOrderSms/modalData']
    },
  },
  methods: {
    sendSms(id) {
      this.$store.dispatch('serviceWorkOrderSms/sendSms', {
        id,
      })
    },
  },
}
</script>
